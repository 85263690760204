import { axiosInstance } from './axios-instance';
import * as endpoints from "../constants/apiConstants";

class ArticleService {
  static postLike(payload) {
    return axiosInstance.post(endpoints.LIKE_ARTICLE, payload).then(res => res.data)
  }

  static getLikes() {
    return axiosInstance.get(endpoints.LIKE_ARTICLE).then(res => res.data)
  }

  static postComments(payload) {
    return axiosInstance.post(endpoints.COMMENT_ARTICLE, payload).then(res => res.data)
  }

  static getComments(payload) {
    return axiosInstance.get(endpoints.COMMENT_ARTICLE + '/?' +
      'cmsId=' + payload.cmsId + '&' +
      'page=' + payload.page + '&' +
      'size=' + payload.size
    ).then(res => res.data)
  }

  static postRead(payload) {
    return axiosInstance.post(endpoints.READ_ARTICLE, payload).then(res => res.data)
  }

  static getReadArticle() {
    return axiosInstance.get(endpoints.READ_ARTICLE).then(res => res.data)
  }

}

export default ArticleService;