import React from 'react'
import './category-tag.scss'

const CategoryTag = props => {

  const { className, textCategory, category, color } = props;

  return (
    <span className={"f-category-tag" + (category ? "-" + category.toLowerCase() : "") + (className ? className : '')} style={{ backgroundColor: color }}>{textCategory}</span>
  )
}

export default CategoryTag;
