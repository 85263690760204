import React, { useState, useEffect } from 'react'
import './interaction-article.scss'
import { sessionStorage } from "../../../utils/storage";
import ArticleService from '../../../services/article';
import ImageSi from '../../../assets/images/Si.svg';
import ImageNo from '../../../assets/images/No.svg';

const InteractionArticle = props => {

  const { showButtonLikes = true, showCount = false, articleId, type = 1, setLikesCountArticle, nombreDelArticulo = "articulo" } = props

  const [likesCount, setlikesCount] = useState(0);
  const [localLiked, setLocalLiked] = useState(null);
  const [disLike, setDislike] = useState(false);
  // console.log("disLike", lisLike)
  // console.log("LocalLiked", LocalLiked)

  useEffect(() => {
    !localStorage.getItem("LOCAL_ARTICLES_LIKES") && localStorage.setItem("LOCAL_ARTICLES_LIKES", JSON.stringify([]))
    initLocalLiked();
    showCount && getLikesCount();
  }, [])

  const isLocalLiked = () => {
    return JSON.parse(localStorage.getItem("LOCAL_ARTICLES_LIKES")).filter(idArticle => idArticle === articleId).length >= 1 ? true : null
  }

  const initLocalLiked = () => {
    setLocalLiked(isLocalLiked());
  }

  const handleClickLike = () => {
    let localLikes = JSON.parse(localStorage.getItem("LOCAL_ARTICLES_LIKES"));
    let payload = {
      option: localLiked ? "DISLIKE" : "LIKE",
      cmsId: articleId,
    }

    ArticleService.postLike(payload).then(res => {
      if (200 === res.status) {
        // getLikesCount()
        if (!localLiked) {
          localLikes.push(articleId);
        } else {
          localLikes.splice(localLikes.indexOf(articleId), 1);
        }

        localStorage.setItem(
          "LOCAL_ARTICLES_LIKES",
          JSON.stringify(localLikes)
        )
        setlikesCount(res.data.like)
        type === 2 && setLikesCountArticle(formatNumber(res.data.like))
        setLocalLiked(!localLiked)
      } else {
        console.log(res)
      }
    }).catch(e => console.log(e))
  }

  const getLikesCount = () => {
    ArticleService.getLikes().then((res) => {
      if (res.status == 200) {
        res.data - find()
        res.data.forEach((like) => {
          if (like.cmsId === articleId) {
            let likesCount = like.like >= 0 ? like.like : 0
            setlikesCount(likesCount)
            type === 2 && setLikesCountArticle(formatNumber(likesCount))
          }
        })
      } else {
        console.log(res)
      }
    }).catch(e => console.log(e))
  }

  const formatNumber = (num) => {
    if (num > 999999) {
      return parseInt(num / 1000000, 10).toString() + 'M'
    } else if (num > 999) {
      return parseInt(num / 1000, 10).toString() + 'K'
    } else {
      return num
    }
  }

  return (
    <div className="f-interactions-container">
      {type === 1 ?
        showButtonLikes &&
        <div className="f-interaction f-likes-container">
          {showCount && <div className={"f-interaction-value " + (localLiked ? "liked" : '')}>{formatNumber(likesCount)}</div>}
          <i className={"fas fa-heart f-icon-like " + (localLiked ? "liked" : '')} onClick={e => { e.stopPropagation(); e.preventDefault(); handleClickLike() }}></i>
        </div>
        :

        <div className="f-interaction-container">
          <p className="f-interaction-title">¿Te gustó esta información?</p>
          <div className="f-interaction-items">
            <div className="f-interaction-item" onClick={e => {
              e.stopPropagation(); e.preventDefault(); handleClickLike()
              localLiked === false && setDislike(false)
            }}>
              <img src={ImageSi} alt="Imagen si" className={localLiked === true ? 'active' : ''} />
              <span>Si</span>
            </div>

            <div className="f-interaction-item" onClick={e => {
              e.stopPropagation(); e.preventDefault();
              disLike ? setDislike(false) : setDislike(true)
              localLiked === true && handleClickLike()
            }}>
              <img src={ImageNo} alt="Imagen no" className={disLike === true ? 'active' : ''} />
              <span>No</span>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default InteractionArticle;
