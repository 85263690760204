import React from 'react'
import homeLink from '../../../utils/siteUrl'
import CategoryTag from '../category-tag/category-tag'
import InteractionArticle from '../../atoms/interaction-article/interaction-article'
import './card-article.scss'

const CardArticle = props => {

  const { className, article, type = 1, onClick, parentSlug = "blog-de-expertos/", category } = props;
  const categoryActive = category ? category : (article.categoriasDelArticulo && article.categoriasDelArticulo !== null) ? article.categoriasDelArticulo[0] : null;

  const getRedirection = () => {
    return homeLink + parentSlug + article.slugDelArticulo
  }

  // const date = () => {
  //   let date = new Date(article.fechaDePublicacionDelArticulo);
  //   let month = date.getMonth().toString().length === 1 ? "0" + date.getMonth() : date.getMonth()
  //   return date.getDate() + "/" + month + "/" + date.getFullYear();
  // }


  if (type === 2) {
    return (
      <a className={"f-card-article-container" + "-type".concat(type + " ") + (className ? className : '')} onClick={onClick} href={getRedirection()}>
        <div className={"f-card-article"}>
          <InteractionArticle articleId={article.contentful_id} nombreDelArticulo={article.nombreDelArticulo}/>
          <figure className="f-card-article-img">
            {article.imagenDePrevisualizacionDelArticulo!==null&& <img src={article.imagenDePrevisualizacionDelArticulo.file.url} alt={article.imagenDePrevisualizacionDelArticulo.description} />}
            <div className="f-card-article-category-date">
              {(categoryActive && categoryActive !== null) && <CategoryTag textCategory={categoryActive.nombreCategoria} category={categoryActive.nombreCategoria} color={categoryActive.colorCategoria} />}
            </div>
          </figure>
          <div className="f-card-article-content">
            <div className="f-card-article-category-date-mobile">
              {(categoryActive && categoryActive !== null) && <CategoryTag textCategory={categoryActive.nombreCategoria} category={categoryActive.nombreCategoria} color={categoryActive.colorCategoria} />}
            </div>
            <h3 className="f-card-article-title">{article.nombreDelArticulo}</h3>
            <div className="f-card-autor-date">
              <p className="f-card-article-autor">{article.autorDelArticulo}</p>
              < span className="f-text-bold f-article-date">{article.fechaDePublicacionDelArticulo}</span>
            </div>
          </div>
        </div>
      </a >
    )
  } else if (type === 3) {
    return (
      <a className={"f-card-article-container" + "-type".concat(type + " ") + (className ? className : '')} onClick={onClick} href={getRedirection()}>
        <div className={"f-card-article"}>
          <InteractionArticle articleId={article.contentful_id} nombreDelArticulo={article.nombreDelArticulo}/>
          <figure className="f-card-article-img">
            {article.imagenDePrevisualizacionDelArticulo!==null&& <img src={article.imagenDePrevisualizacionDelArticulo.file.url} alt={article.imagenDePrevisualizacionDelArticulo.description} />}
            <div className="f-card-article-category-date">
              <h3 className="f-card-article-title">{article.nombreDelArticulo}</h3>
              {(categoryActive && categoryActive !== null) && <CategoryTag textCategory={categoryActive.nombreCategoria} category={categoryActive.nombreCategoria} />}
              <div className="f-card-autor-date">
                <p className="f-card-article-autor">{article.autorDelArticulo}</p>
                < span className="f-text-bold f-article-date">Leer</span>
              </div>
            </div>
          </figure>
        </div>
      </a >
    )
  } else {
    return (
      <a className={"f-card-article-container " + (className ? className : '')} onClick={onClick} href={getRedirection()}>
        <div className="f-card-article">
          <InteractionArticle articleId={article.contentful_id} nombreDelArticulo={article.nombreDelArticulo}/>
          <figure className="f-card-article-img">
            {article.imagenDePrevisualizacionDelArticulo!==null&& <img src={article.imagenDePrevisualizacionDelArticulo.file.url} alt={article.imagenDePrevisualizacionDelArticulo.description} />}
            <div className="f-card-article-category-date">
              {(categoryActive && categoryActive !== null) && <CategoryTag textCategory={categoryActive.nombreCategoria} category={categoryActive.nombreCategoria} />}
              <span className="f-text-bold f-article-date">{article.fechaDePublicacionDelArticulo}</span>
            </div>
          </figure>
          <div className="f-card-article-content">
            <h3 className="f-card-article-title">{article.nombreDelArticulo}</h3>
            <p className="f-card-article-autor">{article.autorDelArticulo}</p>
            {(article.descripcionCortaDelArticulo && article.descripcionCortaDelArticulo !== null) && <p className="f-card-article-description">{article.descripcionCortaDelArticulo.descripcionCortaDelArticulo}</p>}
            <span className="f-card-read-more">Leer más</span>
          </div>
        </div>
      </a >
    )
  }
}

export default CardArticle;
